<template>
  <div>
    <div class="box_title flex sm-" style="justify-content: space-between;">
      <Title :title="title_list[$route.query.id - 1].title" :backShow="false"/>
      <div class="flex">
        <button @click.stop="back" class="but">返回</button>
        <button @click.stop="choose" class="but">选取草稿</button>
      </div>
    </div>
    <div class="ct_box">
      <!-- 封面 -->
      <!--      <upLoadImg v-if="$route.query.id == 4" :imageUrl="$store.state.FillForm.cover_image" title="封面"-->
      <!--                 @get_img="get_img"/>-->
      <div class="coverPageUpload" v-if="$route.query.id == 4">
        <span>封面</span>
        <el-upload
            class="avatar-uploader"
            action="/api/common/upload"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
        >
          <img v-if="$store.state.FillForm.cover_image!==''" :src="$store.state.FillForm.cover_image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>

      <upLoadVideo v-if="$route.query.id == 6 || $route.query.id == 7" title="上传视频" @getVideo="getVideo"/>

      <div class="flex input_box" style="justify-content:flex-start" v-show="$route.query.id == 7">
        <div class="title_box">
          <p class="title">视频预览</p>
        </div>
        <video :src="$store.state.FillForm.video_url" controls="controls" class="contentVideo"></video>
      </div>
      <el-form :model="$store.state.FillForm" :rules="rules" ref="$store.state.FillForm">
        <el-form-item prop="title">
          <!-- <el-input v-model="$store.state.FillForm.title" placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知"></el-input> -->
          <div class="flex input_box">
            <div class="title_box">
              <p class="title"><span>*</span>稿件标题</p>
            </div>
            <!-- <input class="input_text" v-model="$store.state.FillForm.title"
              placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知" /> -->
            <el-input class="title_input" v-model="$store.state.FillForm.title"
                      placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知"></el-input>
            <span class="num">{{ $store.state.FillForm.title.length }}/50</span>
          </div>
        </el-form-item>
      </el-form>

      <fillTimer @get_timer="get_timer"/>

      <div class="flex input_box">
        <div class="title_box">
          <p class="title">转载链接</p>
        </div>
        <input class="input_text" v-model="$store.state.FillForm.url"
               placeholder="若填写稿件转载链接，则首选以链接内容发布"/>
      </div>
      <!-- 公众号转载链接 -->
      <div class="flex input_box" v-if="$route.query.id == 4">
        <div class="title_box">
          <p class="title">原文链接</p>
        </div>
        <input class="input_text" v-model="$store.state.FillForm.original_url"
               placeholder="例如:https://www.xxx.com/"/>
      </div>
      <div class="flex input_box">
        <div class="title_box">
          <p class="title">备注信息</p>
        </div>
        <input class="input_text" v-model="$store.state.FillForm.remark"
               placeholder="发稿有什么特殊要求请写上，没有请不要填写"/>
      </div>
      <div>
        <fill_upload @upload_success="upload_success"/>
        <div class="flex input_box">
          <div class="title_box2">
            <p class="title"><span></span>稿件内容</p>
          </div>
          <div style="width: 50%;">
            <vue-ueditor-wrap id="tinymce" v-model="$store.state.FillForm.content"
                              :config="myConfig"></vue-ueditor-wrap>
          </div>
        </div>
        <div class="input_box flex">
          <div class="title_box">
            <p class="title"><span></span></p>
          </div>
          <button class="draft_but" @click="adddRaft">存为草稿</button>
        </div>
      </div>
    </div>
    <SetMenuButNav/>
  </div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import VueUeditorWrap from 'vue-ueditor-wrap'
import SetMenuButNav from '@/components/SetMenuButNav.vue'
import {mapState, mapMutations} from 'vuex'
import {empty} from '@/util/emptyFillDate'
import {fill_title_rules} from '@/util/fill_title_rules'
import fillTimer from '@/components/fillTimer.vue'
import fill_upload from '@/components/fill_upload'

export default {
  name: '',
  computed: {
    ...mapState(['FillForm', 'shopObj']),
    ...mapMutations(['login_tab', 'SHOP_RESET']),
  },

  components: {
    Title,
    VueUeditorWrap,
    SetMenuButNav,
    fillTimer,
    fill_upload
  },
  mounted() {
    this.getDetail()
    this.getUserInfo()
    // console.log(this.$route.query.id)

    // this.getmonthValue()
    // this.tabShow()

  },
  data() {
    return {
      user_info: {},
      headers: {
        token: ''
      },
      title_list: [{
        id: 1,
        title: '资讯门户-发布'
      },
        {
          id: 2,
          title: '自媒体-发布'
        },
        {
          id: 3,
          title: '纸媒-发布'
        },
        {
          id: 4,
          title: '公众号-发布'
        },
        {
          id: 5,
          title: '微博-发布'
        },
        {
          id: 6,
          title: '小红书-发布'
        },
        {
          id: 7,
          title: '短视频-发布'
        },
        {
          id: 99,
          title: '媒介套餐-发布'
        },
      ],
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 400,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: `/api/common/getupload?token=${this.$user_info.token}`,
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: '/UEditor/',
        initialContent: '1.所提供文章必须符合国家广告法内容，若文章存在违规被删除，本平台不予处理；<br/>2.Word文档上传后请仔细检查内容是否有误，一经发布无法撤回和修改，请确认后再操作；<br/>3.署名、作者名、免责声明等特殊内容不保证，网媒没标明不包时效的，默认1个月。<br/>4.图片文件最大上传不超过3MB；<br/>5.视频文件最大上传不超过20MB；',
        autoClearinitialContent: true,
        toolbars: [
          [
            "fullscreen",
            "source",	// html 源码
            "|",
            "undo",
            "redo",
            "|",
            "link",
            "unlink",
            "|",
            "simpleupload",
            "insertimage",
            "insertvideo",
            "|",
            "bold",
            "forecolor",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "fontfamily",
            "fontsize",
            "|",
            "removeformat",
            "formatmatch",
            "autotypeset",
          ]
        ],
      },
      rules: {
        title: [
          {required: true, message: '请输入标题名称', trigger: 'blur'},
          {min: 1, max: 50, message: '标题长度在 1 到 50 个字符', trigger: 'blur'}
        ],
      },

    }
  },
  methods: {
    getUserInfo() {
      this.curlGet('/api/user/info').then((res) => {
        // console.log(res, 'res')
        if (res.data.code === 1) {
          this.headers.token = res.data.data.token;

        }
      })
    },
    handleAvatarSuccess(response, file, fileList) {
      // console.log(response.data.url, file, fileList)
      this.$store.state.FillForm.cover_image = response.data.url
    },
    upload_success(data) {
      console.log(data);
      this.$store.state.FillForm.content = data.content
      this.$store.state.FillForm.file = data.url
    },
    get_timer(time) {
      this.$store.state.FillForm.limit_time = time || []
    },
    get_img(url) {
      console.log('Fill,getImg', url)
      this.$store.state.FillForm.cover_image = url
    },
    getVideo(url) {
      this.$store.state.FillForm.video_url = url
      if (this.$route.query.id == 6) {
        this.$store.state.FillForm.content += `<video controls="controls" src="${url}" class="contentVideo"></video><p><br/></p>`
        // let d = document.getElementById("contentVideo");
        // console.log(d);
      }
    },
    // 选取草稿
    choose() {
      this.$store.commit('childTab', '草稿箱')
      return this.$router.push('/user/draft')
    },
    // 存为草稿
    adddRaft() {
      let data = {}
      const titleStatus = fill_title_rules(this.$store.state.FillForm.title)
      console.log(titleStatus);
      if (!titleStatus) {
        return
      }
      if (this.$store.state.shopObj[0]) {
        data.category_id = this.$store.state.shopObj[0].category_id
      } else if (this.$route.query.id && this.$route.query.id != 8) {
        data.category_id = this.$route.query.id
      } else {
        data.category_id = 0
      }
      console.log(data.category_id);

      this.$store.state.shopObj.forEach((item, index) => {
        if (item.medium_ids > 0) {
          this.$store.state.FillForm.medium_ids += `${item.medium_ids},`
        }
        if (item.package_ids > 0) {
          this.$store.state.FillForm.package_ids += `${item.package_ids},`
        }
      })
      if (this.$store.state.FillForm.medium_ids != '') {
        data.medium_ids = ''
        data.medium_ids = this.$store.state.FillForm.medium_ids
      }
      if (this.$store.state.FillForm.package_ids != '') {
        data.package_ids = this.$store.state.FillForm.package_ids
      }
      if (this.$store.state.FillForm.time !== '') {
        data.limit_time = this.$store.state.FillForm.time
      }
      if (this.$store.state.FillForm.title != '') {
        data.title = this.$store.state.FillForm.title
      }
      if (this.$store.state.FillForm.content != '') {
        data.content = this.$store.state.FillForm.content
      }

      if (data.title == '' && data.content == '') {
        return this.$message({
          message: '标题和内容至少要填写一项',
          type: 'warning',
        })
      }
      data.file = this.$store.state.FillForm.file

      if (this.$store.state.FillForm.remark !== '') {
        data.remark = this.$store.state.FillForm.remark
      }
      if (this.$store.state.FillForm.url !== '') {
        data.url = this.$store.state.FillForm.url
      }
      if (this.$store.state.FillForm.cover_image !== '') {
        data.cover_image = this.$store.state.FillForm.cover_image
      }
      if (this.$store.state.FillForm.video_url !== '') {
        data.video_url = this.$store.state.FillForm.video_url
      }
      if (this.$store.state.FillForm.original_url != '') {
        data.original_url = this.$store.state.FillForm.original_url
      }
      this.curlPost('/api/users/draft/add', data).then(res => {
        if (res.data.code) {
          this.$message({
            message: '添加成功',
            type: 'success',
          })
          this.$store.state.shopObj = []
          this.$store.state.FillForm = empty()
          this.$store.commit('childTab', '草稿箱')
          return this.$router.push('/user/Draft')
        } else {

        }
      })
    },
    back() {
      // console.log(this.$store.state.shopObj[0].right_package_id)
      if (this.$store.state.shopObj[0].right_package_id != undefined) {
        this.$router.push({
          path: '/user/userBenefitPackage',
        })
      } else {
        this.$store.commit('childTab', '媒介资源')
        switch (this.$route.query.id) {
          case '1':
            this.$router.push({
              path: '/user/user_media/News',
            })
            break;
          case '2':
            this.$router.push({
              path: '/user/user_media/UserWeMedia',
            })
            break;
          case '3':
            this.$router.push({
              path: '/user/user_media/UserPaperMedia',
            })

            break;
          case '4':
            this.$router.push({
              path: '/user/user_media/UserOfficialAccounts',
            })
            break;
          case '5':
            this.$router.push({
              path: '/user/user_media/UserWb',

            })

            break;
          case '6':
            this.$router.push({
              path: '/user/user_media/UserRedBook',
            })
            break;
          case '7':
            this.$router.push({
              path: '/user/user_media/UserShortVideo',
            })
            break;
          case '8' || '0':
            this.$store.commit('SETACTIVEMENU', '/user/SetMenu/SetMenuList')
            this.$router.push({
              path: '/user/SetMenu/SetMenuList',

            })
            break;
          default:
        }
      }
    },
    getDetail() {
      if (this.$route.query.order_id) {
        this.curlGet('/api/users/order/detail', {
          id: this.$route.query.order_id
        }).then(res => {
          if (res.data.code) {
            let data = res.data.data[0]
            this.$store.commit('SHOP_RESET', [])
            this.$store.state.FillForm = {
              category_id: data.category_id,
              title: data.title,
              content: data.content,
              limit_time: data.limit_time ? data.limit_time : '',
              file: '',
              url: data.url,
              video_url: data.video_url,
              original_url: data.original_url,
              remark: data.remark,
              medium_ids: '',
              package_ids: '',
              cover_image: data.cover_image,
            }
          }
        })
      }
    },
    // tabShow() {
    // 	switch (this.$route.query.id) {
    // 		case '1':
    // 			this.$store.commit('childTab', '资讯门户发布')
    // 			break;
    // 		case '2':
    // 			this.$store.commit('childTab', '自媒体发布')
    // 			break;
    // 		case '3':
    // 			this.$store.commit('childTab', '纸媒介发布')

    // 			break;
    // 		case '4':
    // 			this.$store.commit('childTab', '公众号发布')

    // 			break;
    // 		case '5':
    // 			this.$store.commit('childTab', '微博发布')

    // 			break;
    // 		case '6':
    // 			this.$store.commit('childTab', '小红书发布')

    // 			break;
    // 		case '7':
    // 			this.$store.commit('childTab', '短视频发布')
    // 			break;
    // 		case '8':
    // 			this.$store.commit('childTab', '套餐发布')

    // 			break;
    // 		default:
    // 			break;
    // 	}
    // }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/Fill';
</style>